
import { Component, Vue } from 'vue-property-decorator'
import * as echarts from 'echarts'
import { WaterDetail } from '../../types/ecologyMonitor.d'

@Component
export default class WaterHistory extends Vue {
  private paramList: Array<{ icon: string; name: string; value: string; data: Array<string> }> = [
    {
      icon: 'shuiwei2',
      name: '水位(m)',
      value: 'waterLevel',
      data: []
    },
    {
      icon: 'shuiwen2',
      name: '水温(℃)',
      value: 'temperature',
      data: []
    },
    {
      icon: 'zhuodu2',
      name: '浊度(NTU)',
      value: 'turbidity',
      data: []
    },
    {
      icon: 'rongjieyang2',
      name: '溶解氧(mg/L)',
      value: 'dissolvedOxygen',
      data: []
    },
    {
      icon: 'andan2',
      name: '氨氮(mg/L)',
      value: 'ammoniaNitrogen',
      data: []
    },
    {
      icon: 'diandaolv2',
      name: '电导率(μS/cm)',
      value: 'conductivity',
      data: []
    },
    {
      icon: 'ph2',
      name: 'pH',
      value: 'ph',
      data: []
    }
  ]

  private collectTime: Array<string> = []

  private deviceData = {}
  private finds = []
  private dataList: Array<WaterDetail> = []
  private echartSelect = ''
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private typeColor = [
    'rgba(19, 177, 244, 1)',
    'rgba(19, 177, 244, 1)',
    'rgba(90, 112, 220, 1)',
    'rgba(24, 144, 255, 1)',
    'rgba(0, 189, 156, 1)',
    'rgba(47, 194, 90, 1)',
    'rgba(248, 204, 0, 1)'
  ]

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private echart: any

  get deviceNumber () {
    return this.$route.params.deviceNumber as string
  }

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
    this.getDetail()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const val = this.$dayjs().format('YYYY-MM-DD')
    const vals = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    this.searchInfo.dateRange = [vals, val]
  }

  getDetail () {
    this.$axios.get(this.$apis.ecologyMonitor.selectWaterQualityInfoByDeviceNumber, {
      deviceNumber: this.deviceNumber
    }).then((res) => {
      this.deviceData = res
    })
  }

  getData () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.ecologyMonitor.selectWaterQualityDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.finds = res.finds
        this.echartSelect = res.finds[0]
        this.dataList = res.waterQualityInfo.list
        this.getChartData()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  getChartData () {
    this.paramList.forEach(item => {
      item.data = []
    })
    this.collectTime = []
    const index = this.paramList.findIndex((item: any) => item.value === this.echartSelect)
    const title = this.paramList[index].name
    const color = this.typeColor[index]
    if (this.dataList.length > 0) {
      this.dataList.forEach(item => {
        this.paramList[0].data.push(item.waterLevel)
        this.paramList[1].data.push(item.temperature)
        this.paramList[2].data.push(item.turbidity)
        this.paramList[3].data.push(item.dissolvedOxygen)
        this.paramList[4].data.push(item.ammoniaNitrogen)
        this.paramList[5].data.push(item.conductivity)
        this.paramList[6].data.push(item.ph)
        this.collectTime.push(item.collectTime)
      })
    }
    this.draw(title, [color], this.collectTime || [], this.paramList[index].data || [])
  }

  changeEchart () {
    this.getChartData()
  }

  draw (title: string, color: Array<string>, xData: Array<string>, yData: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const name = title.indexOf('(') > 0 ? title.substring(0, title.indexOf('(')) : title
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 40,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        title: {
          text: title,
          textStyle: {
            fontSize: 18,
            fontWeight: 400,
            color: '#000000'
          }
        },
        legend: {
          icon: 'rect',
          top: 40,
          left: 0,
          itemWidth: 20,
          itemHeight: 1,
          itemGap: 5,
          textStyle: {
            color: '#8C8C8C'
          }
        },
        backgroundColor: 'transparent',
        color: color,
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: name,
          type: 'line',
          symbolSize: 0,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          data: yData
        }]
      }

      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  // 判断展示字段
  getFind (str: string) {
    return this.finds.find((item) => item === str)
  }
}
